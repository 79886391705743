import React, { useEffect, useState } from "react";
import { API_CAT } from "../../../api/api";
import Select from "react-select";
import { createOptions, getIndustryBySicCode } from "../../../data/staticData";
import { colourStyles } from "../../utils/Functions";
// import RadarChart from "../../utils/RadarChart";
import { FaBookOpen } from "react-icons/fa6";
import SicCodeBox from "../../utils/SicCodeBox";
import { LuLoader2 } from "react-icons/lu";
import {
  CAT_actionColor,
  CAT_formInputs,
  CAT_marketSegments,
  CAT_partnername,
  CAT_productbaskets,
  CAT_scoreAction,
  CAT_states,
} from "../../../data/modules/CAT";
import { NumberFormatter, changeDate } from "../../utils/Utils";

const CAT2023_ScoreCalculator = () => {
  const [inputFormData, setInputFormData] = useState(CAT_formInputs);
  const [scoreData, setScoreData] = useState(null);
  const [showSic, setShowSic] = useState(false);
  const [sic, setSic] = useState(null);
  const [inputsFilled, setInputsFilled] = useState(false);
  const [load, setLoad] = useState(false);

  // handle input changes
  const handleOnChangeInput = (e, name) => {
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "sic") {
      let industry = getIndustryBySicCode(e.target?.value);
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target?.value),
        industry: industry,
      });
      setSic({ sic: e.target?.value, industry: industry });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target?.value,
      });
    }
  };

  // hande number inputs
  const handleNumberInput = (e) => {
    const removeComma = e.target.value?.split(",")?.join("");
    const value = parseInt(removeComma ? removeComma : 0);
    setInputFormData({
      ...inputFormData,
      [e.target.name]: value,
    });
  };

  // handle multi select input
  const handleMultiSelect = (e) => {
    // console.log(e);
    let prod_basket = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (e.length === 0) {
      setInputFormData({
        ...inputFormData,
        product_basket: prod_basket,
      });
      return;
    }
    e.map((obj) => {
      const idx = obj.index;
      if (idx < 2) {
        prod_basket[idx] = 1
      } 
      else {
        prod_basket[idx - 1] = 1;
      }
      // prod_basket[idx-1] = 1;
      setInputFormData({
        ...inputFormData,
        product_basket: prod_basket,
      });
    });
  };

  // handle date input
  const handleDateInput = (e) => {
    setInputFormData({
      ...inputFormData,
      [e.target.name]: changeDate(e.target.value),
    });
  };

  // console.log(inputFormData);

  // function to get score
  const getScore = async (e) => {
    e.preventDefault();
    setLoad(true);
    setShowSic(false);
    setScoreData(null);

    let inputEmpty = false;

    // Check if all fields are filled then set required true
    await Object.entries(inputFormData).forEach(([key, value]) => {
      if (!value) {
        console.log(key);
        inputEmpty = true;
        setInputsFilled(true);
        return;
      }
    });

    // ends function if inputs are empty
    if (inputEmpty) {
      setLoad(false);
      return;
    } else {
      setInputsFilled(false);
    }

    // runs only after all input are filled
    await API_CAT.post("/slf/catscore2023-scorecalculator", inputFormData)
      .then(({ data }) => {
        // console.log(data);
        setLoad(false);
        const score = data.score;
        setScoreData({ score: score, action: CAT_scoreAction(score) });
      })
      .catch((error) => {
        setLoad(false);
        // console.log(error);
      });
  };

  // reset form
  const resetForm = () => {
    setInputFormData(CAT_formInputs);
    setScoreData(null);
  };

  // update sic
  useEffect(() => {
    if (sic) {
      setInputFormData({
        ...inputFormData,
        sic: sic.sic,
        industry: sic.industry,
      });
      setShowSic(false);
    }
  }, [sic]);

  return (
    <div className="p-5 flex h-max gap-16">
      <div className="p-7 bg-white rounded-lg shadow-md border w-fit text-xs text-gray-600">
        <h2 className="text-base font-bold mb-5">
          Case Characteristics:{" "}
          <span className={inputsFilled ? "text-red-500" : "hidden"}>
            (Some Fields are missing)
          </span>
        </h2>
        <form className="relative" onSubmit={(e) => getScore(e)}>
          {/* Inputs */}
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex gap-10 justify-between">
              {/* Product Basket */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="state">
                  Product Basket: <sup className="text-red-500">*</sup>
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="product_basket_options"
                  options={CAT_productbaskets}
                  className="w-[425px]"
                  onChange={(e) =>
                    handleMultiSelect(e, "product_basket_options")
                  }
                  closeMenuOnSelect={false}
                  isMulti
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Benefit Lives */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="benefit_lives">
                  Benefit Lives: <sup className="text-red-500">*</sup>
                </label>
                <input
                  type="text"
                  name="benefit_lives"
                  id="benefit_lives"
                  onChange={(e) => handleNumberInput(e)}
                  value={NumberFormatter(inputFormData.benefit_lives) || ""}
                  maxLength={10}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                />
              </div>
              {/* Benefit Count */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="benefit_count">
                  Benefit Count: <sup className="text-red-500">*</sup>
                </label>
                <input
                  type="text"
                  name="benefit_count"
                  id="benefit_count"
                  onChange={(e) => handleNumberInput(e)}
                  value={NumberFormatter(inputFormData.benefit_count) || ""}
                  maxLength={10}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                />
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Market Segment */}
              <div className="flex flex-col gap-1">
                <label
                  className="font-semibold"
                  htmlFor="market_segment_indicator"
                >
                  Market Segment: <sup className="text-red-500">*</sup>
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="market_segment_indicator"
                  options={createOptions(CAT_marketSegments)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) =>
                    handleOnChangeInput(e, "market_segment_indicator")
                  }
                ></Select>
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Received Date */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="month">
                  Received Date: <sup className="text-red-500">*</sup>
                </label>
                <input
                  type="date"
                  name="case_create_date"
                  id="case_create_date"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  onChange={(e) => handleDateInput(e)}
                />
              </div>
              {/* Effective Date */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="month">
                  Effective Date: <sup className="text-red-500">*</sup>
                </label>
                <input
                  type="date"
                  name="case_benefit_effective_date"
                  id="case_benefit_effective_date"
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  onChange={(e) => handleDateInput(e)}
                />
              </div>
            </div>
            <div className="flex gap-10 justify-between">
              {/* Partner Name */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="partner_name">
                  Partner Name: <sup className="text-red-500">*</sup>
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="partner_name"
                  options={createOptions(CAT_partnername)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "partner_name")}
                ></Select>
              </div>
              {/* Sponsor State */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="sponsor_state">
                  Sponsor State:
                </label>
                <Select
                  styles={colourStyles}
                  isSearchable={true}
                  name="sponsor_state"
                  options={createOptions(CAT_states)}
                  classNamePrefix="inventory"
                  className="w-48"
                  onChange={(e) => handleOnChangeInput(e, "sponsor_state")}
                ></Select>
              </div>
            </div>
            <div className="flex justify-between">
              {/* SIC Code */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="sic">
                  SIC Code: <sup className="text-red-500">*</sup>
                </label>
                <input
                  type="text"
                  name="sic"
                  id="sic"
                  onChange={(e) => handleOnChangeInput(e)}
                  onFocus={() => setShowSic(true)}
                  value={inputFormData.sic || ""}
                  maxLength={4}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-40"
                />
              </div>
              {/* Open Code Book */}
              <div className="flex flex-col gap-1 justify-end">
                <div
                  onClick={() => setShowSic(!showSic)}
                  className={`p-2 text-xl border rounded-md hover:bg-gray-500 hover:text-white cursor-pointer ${
                    showSic && "bg-gray-500 text-white"
                  }`}
                >
                  <FaBookOpen />
                </div>
                {/* Open Book */}
                {showSic && (
                  <SicCodeBox
                    show={showSic}
                    setSic={setSic}
                    sic={sic}
                    setShow={setShowSic}
                  />
                )}
              </div>
              {/* Industry */}
              <div className="flex flex-col gap-1">
                <label className="font-semibold" htmlFor="industry">
                  Industry:
                </label>
                <input
                  type="text"
                  name="industry"
                  id="industry"
                  onChange={(e) => handleOnChangeInput(e)}
                  value={inputFormData.industry || ""}
                  className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                  disabled
                />
              </div>
            </div>
          </div>
          {/* Actions */}
          <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
            <input
              type="reset"
              value="Reset"
              onClick={() => resetForm()}
              className="px-5 py-2 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
            />
            {/* Get Score */}
            {load ? (
              <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                <span className="animate-spin">
                  <LuLoader2 />
                </span>
                <span>Processing...</span>
              </div>
            ) : (
              <input
                type="submit"
                value="Get Score"
                onClick={(e) => getScore(e)}
                className="px-5 py-2 bg-tertiary rounded-md text-white cursor-pointer hover:bg-opacity-80"
              />
            )}
          </div>
        </form>
      </div>
      {scoreData !== null && (
        <div className="w-3/5 bg-white rounded-lg flex justify-center items-center">
          <div className="shadow-md rounded-lg overflow-auto border w-full">
            <table className="text-center overflow-auto w-full">
              <thead>
                <tr className="border-b">
                  <th className="p-2 border-r">Score</th>
                  <th className="p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td
                    className={`p-2 font-bold border-r w-40 ${CAT_actionColor(
                      scoreData.score
                    )}`}
                  >
                    {scoreData.score}
                  </td>
                  <td
                    className={`p-2 font-bold w-40 ${CAT_actionColor(
                      scoreData.score
                    )}`}
                  >
                    {scoreData.action}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="pt-3 rounded-lg text-sm text-center mb-2 text-gray-500">
              <span className="text-red-500 font-normal">*</span> Score is based
              on the propensity of case being sold
            </div>
          </div>
          {/* <RadarChart data={scoreData} module={"Lead Prioritizer"} /> */}
        </div>
      )}
    </div>
  );
};

export default CAT2023_ScoreCalculator;
