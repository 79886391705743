import React, { useContext, useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { LuLoader2 } from "react-icons/lu";
import API from "../../api/api";
import { FaCheck, FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";

// time left in timer in minutes
const calculateTimeLeft = (minutes = 0, seconds = 0) => {
  const futureTime = new Date();
  futureTime.setMinutes(futureTime.getMinutes() + minutes);
  futureTime.setSeconds(futureTime.getSeconds() + seconds);

  const difference = futureTime - new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeft = { minutes: 0, seconds: 0 };
  }
  return timeLeft;
};

const ForgotPassword = () => {
  const { userActivityTracker } = useContext(UserContext);
  const [progress, setProgress] = useState("Verify Email");
  const [viewInfo, setViewInfo] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [resend, setResend] = useState(false);
  const [resendLimit, setResendLimit] = useState(3);
  const [counter, setCounter] = useState(calculateTimeLeft(9, 59));
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);

  // handle username or email verification
  const verifyUserAccount = (e) => {
    e.preventDefault();
    setLoad(true);
    setError(null);

    // Check if email or username is entered
    if (progress === "Verify Email" && username === "") {
      setError("Please enter username or email to proceed");
      return setLoad(false);
    }

    // API request to backend
    API.post("forgot-password", { username: username })
      .then(({ data }) => {
        setEmail(data.email);
        setTimeout(() => {
          setProgress("Verify Code");
          setLoad(false);
          setResend(false);
          setCounter(calculateTimeLeft(9, 59));
        }, 500);
        userActivityTracker(
          "User wants to reset password and has been sent a one time code",
          "Forgot password page",
          username
        );
      })
      .catch(({ response }) => {
        // console.log(response);
        userActivityTracker(
          "Reset password attempted",
          "Forgot password page",
          username
        );
        setError(response?.data?.message);
        setLoad(false);
      });
    // setProgress("verifyCode");
  };

  // handle code verification
  const verifyCode = (e) => {
    e.preventDefault();
    setLoad(true);
    setError(null);

    // Check if code is entered
    if (progress === "Verify Code" && code === null) {
      setError("Please enter valid code to proceed");
      return setLoad(false);
    } else {
    }

    API.post("/security-authentication", { code: code })
      .then((res) => {
        // console.log(res);
        setTimeout(() => {
          setProgress("Set New Password");
          setLoad(false);
          userActivityTracker(
            "User verified their account and will proceed to reset password",
            "Forgot password page",
            username
          );
        }, 500);
      })
      .catch(({ response }) => {
        // console.log(response);
        setTimeout(() => {
          setError(response?.data?.message);
          setLoad(false);
          userActivityTracker(
            `User entered the wrong code, ${resendLimit} attempts left`,
            "Forgot password page",
            username
          );
        }, 500);
      });
  };

  // handle new password
  const setNewPassword = (e) => {
    e.preventDefault();
    setLoad(true);
    setError(null);

    // Check if user has entered his password
    if (progress === "Set New Password" && password.newPassword === "") {
      setError("Please enter new password according to the instructions");
      return setLoad(false);
    } // Check if password match
    else if (
      progress === "Set New Password" &&
      password.newPassword !== password.confirmPassword
    ) {
      setError("Passwords do not match!");
      return setLoad(false);
    }

    setPassword({
      ...password,
      email: email,
    });

    API.put("/forgot-password", { ...password, email: email })
      .then((res) => {
        // console.log(res);
        setTimeout(() => {
          setProgress("Reset Successful");
          setCounter(calculateTimeLeft(0, 5));
          setLoad(false);
          userActivityTracker(
            "User has reset their password",
            "Forgot password page",
            username
          );
        }, 500);
      })
      .catch(({ response }) => {
        // console.log(response);
        setTimeout(() => {
          setError(response?.data?.message);
          setLoad(false);
          userActivityTracker(
            "User failed to correctly set the new password but can reattempt",
            "Forgot password page",
            username
          );
        }, 500);
      });
  };

  // mask email
  const maskEmail = (email) => {
    const emailParts = email?.split("@");
    const username = emailParts[0];
    const domain = emailParts[1];
    let maskedUsername;
    if (username.length > 5) {
      maskedUsername =
        username.slice(0, 2) +
        "*".repeat(username.length - 4) +
        username.slice(-2);
    } else {
      maskedUsername = username.slice(0, 2) + "*".repear(username.length - 2);
    }
    return maskedUsername + "@" + domain;
  };

  // Function to mask the username
  const maskUsername = (username) => {
    if (username.length === 0) {
      return;
    }
    if (username?.includes("@")) {
      const parts = username.split("@");
      const firstTwo = parts[0].slice(0, 2);
      const lastTwo = parts[0].slice(-2);
      return `${firstTwo}${"*".repeat(5)}${lastTwo}@${parts[1]}`;
    }
    const firstTwo = username.slice(0, 2);
    const lastTwo = username.slice(-2);
    return `${firstTwo}${"*".repeat(5)}${lastTwo}`;
  };

  const navigate = useNavigate();

  useEffect(() => {
    // resend code timers
    setTimeout(() => {
      if (progress === "Verify Code" && resend === false) setResend(true);
    }, 2 * 60 * 1000);

    // page validation which decreases timer
    const timer = setInterval(() => {
      setCounter((prevTime) => {
        if (prevTime.minutes === 0 && prevTime.seconds === 0) {
          clearInterval(timer);
          return { minutes: 0, seconds: 0 };
        }
        const totalSeconds = prevTime.minutes * 60 + prevTime.seconds - 1;
        const newMinutes = Math.floor(totalSeconds / 60);
        const newSeconds = totalSeconds % 60;

        return { minutes: newMinutes, seconds: newSeconds };
      });
    }, 1000);

    if (progress === "Reset Successful") {
      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 6000);

      return () => clearTimeout(timer);
    }

    return () => clearInterval(timer);
  }, [progress]);

  return (
    <div className="relative">
      {/* Title */}
      <div className="flex flex-col gap-3 justify-center">
        <h1 className="text-2xl font-extrabold">
          {progress === "Verify Email"
            ? "Register / Forgot Password"
            : progress === "Verify Code"
            ? "Verify One Time Code"
            : progress === "Set New Password"
            ? "Set New Password"
            : progress === "Reset Successful"
            ? "Registration / Reset Complete."
            : ""}
        </h1>
      </div>
      {/* Info Tags */}
      <div className="flex flex-col gap-3 justify-center">
        <div className="flex gap-3 items-center relative">
          {progress !== "Verify Email" && (
            <BsInfoCircle
              className="text-xl cursor-pointer absolute -top-7 right-16"
              onMouseEnter={() => setViewInfo(true)}
              onMouseLeave={() => setViewInfo(false)}
            />
          )}
          {/* Information modal */}
          <div
            className={`absolute bg-gray-100 z-10 p-5 w-5/6 left-32 top-0 rounded-lg text-xs shadow-md ${
              viewInfo ? "block" : "hidden"
            }`}
          >
            {progress === "Verify Code" && (
              <>
                <ul className="list-disc ml-2 grid gap-2">
                  <li>
                    It usually takes about 30 seconds for the email to reach to
                    the user
                  </li>
                  <li>
                    Resending of the code will be enabled after 2 minutes of
                    initial request
                  </li>
                  <li>The code can be resent for a maximum of 3 times</li>
                  <li>
                    If you did not receive an email, please contact Spinnaker
                    Administrator
                  </li>
                </ul>
              </>
            )}
            {progress === "Set New Password" && (
              <>
                <ul className="list-disc ml-2 grid gap-2">
                  <li>
                    The password must meet the following requirements:
                    <ul className="ml-1">
                      <li>Between 8 and 16 characters</li>
                      <li>At least 1 uppercase letter</li>
                      <li>At least 1 lowercase letter</li>
                      <li>At least 1 number</li>
                      <li>
                        At least 1 special character ( ! @ # $ % ^ & * _ )
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Show username prompt to verify user account exists */}
      {progress === "Verify Email" && (
        <form
          action=""
          onSubmit={(e) => verifyUserAccount(e)}
          className="text-sm"
        >
          {/* Username field */}
          <div className="my-10 relative z-0">
            <input
              type="text"
              name="username"
              id="username"
              className={`block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 appearance-none border-gray-500 focus:outline-none focus:ring-0 focus:border-green-500 peer ${
                error ? "border-red-500" : ""
              }`}
              placeholder=""
              value={isUsernameFocused ? username : maskUsername(username)}
              onFocus={() => {
                setIsUsernameFocused(true);
              }}
              onBlur={() => {
                setIsUsernameFocused(false);
              }}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label
              htmlFor="username"
              className="absolute border-gray-500 font-semibold text-black duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Username or email
            </label>
          </div>
          {/* Verify Account */}
          {load ? (
            <div className="flex items-center justify-center gap-2 bg-gray-300 w-full p-2 rounded-md shadow-sm">
              <span className="animate-spin">
                <LuLoader2 />
              </span>
              <span>Verifying...</span>
            </div>
          ) : (
            <button
              type="submit"
              className="bg-tertiary w-full p-2 text-white rounded-md hover:shadow-xl"
              onClick={(e) => verifyUserAccount(e)}
            >
              Verify username / email
            </button>
          )}
          {error && (
            <p className="mt-2 text-xs text-red-500 absolute">{error}</p>
          )}
        </form>
      )}

      {/* Only after veryfying, send code to email*/}
      {progress === "Verify Code" && (
        <form onSubmit={(e) => verifyCode(e)} className="text-sm">
          <div className="pt-5">
            <p>
              A one-time code has been sent to{" "}
              <span className="font-semibold">{maskEmail(email)}</span>. This
              code will expire in{" "}
              <span
                className={`font-semibold ${
                  counter.minutes <= 1 ? "text-red-500" : ""
                }`}
              >
                {counter?.minutes?.toString()?.padStart(2, "0")}:
                {counter?.seconds?.toString()?.padStart(2, "0")}
              </span>
            </p>
          </div>
          {/* Code field */}
          <div className="my-10 relative z-0">
            <input
              type="text"
              name="code"
              id="code"
              maxLength={6}
              className="block tracking-[15px] py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 appearance-none border-gray-500 focus:outline-none focus:ring-0 focus:border-green-500 peer"
              placeholder=""
              onChange={(e) => setCode(parseInt(e.target.value))}
            />
            <label
              htmlFor="code"
              className="absolute border-gray-500 font-semibold text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Code
            </label>
            <span
              className={`flex mt-1 justify-end ${
                resend && resendLimit > 0
                  ? "text-black hover:underline cursor-pointer"
                  : "text-gray-400 pointer-events-none"
              }`}
              onClick={(e) => {
                if (resend) {
                  verifyUserAccount(e);
                  setResendLimit(resendLimit - 1);
                }
              }}
            >
              {resendLimit === 0 ? "Resend code limit reached" : "Resend code"}
            </span>
          </div>
          {/* Verify Account */}
          {load ? (
            <div className="flex items-center justify-center gap-2 bg-gray-300 w-full p-2 rounded-md shadow-sm">
              <span className="animate-spin">
                <LuLoader2 />
              </span>
              <span>Authenticating...</span>
            </div>
          ) : (
            <button
              type="submit"
              className="bg-tertiary w-full p-2 text-white rounded-md hover:shadow-xl"
              onClick={(e) => verifyCode(e)}
            >
              Verify code
            </button>
          )}
          {/* Error messages */}
          {error && (
            <p className="mt-2 text-xs text-red-500 absolute">{error}</p>
          )}
        </form>
      )}

      {/* Only after veryfying code show reset password page */}
      {progress === "Set New Password" && (
        <form onSubmit={(e) => setNewPassword(e)} className="text-sm">
          {/*New Password field */}
          <div className="my-10 relative z-0">
            <input
              type={showPass ? "text" : "password"}
              id="newPassword"
              name="newPassword"
              className="block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 appearance-none border-gray-500 focus:outline-none focus:ring-0 focus:border-green-500 peer"
              placeholder=" "
              onChange={(e) =>
                setPassword({ ...password, newPassword: e.target.value })
              }
            />
            <div
              onMouseDown={() => setShowPass(true)}
              onMouseUp={() => setShowPass(false)}
              className={`${showPass ? "cursor-grabbing" : "cursor-grab"}`}
            >
              {password.newPassword.length > 0 &&
                (showPass ? (
                  <FaRegEyeSlash className="cursor-pointer absolute top-5 right-0 w-12" />
                ) : (
                  <FaRegEye className="cursor-pointer absolute top-5 right-0 w-12" />
                ))}
            </div>
            <label
              for="newPassword"
              className="absolute border-gray-500 text-black font-semibold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              New Password
            </label>
          </div>
          {/* Confirm Password field */}
          <div className="my-10 relative z-0">
            <input
              type={showPass1 ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              className="block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 appearance-none border-gray-500 focus:outline-none focus:ring-0 focus:border-green-500 peer"
              placeholder=" "
              onChange={(e) =>
                setPassword({ ...password, confirmPassword: e.target.value })
              }
            />
            <div
              onMouseDown={() => setShowPass1(true)}
              onMouseUp={() => setShowPass1(false)}
              className={`${showPass1 ? "cursor-grabbing" : "cursor-grab"}`}
            >
              {password.newPassword.length > 0 &&
                (showPass1 ? (
                  <FaRegEyeSlash className="cursor-pointer absolute top-5 right-0 w-12" />
                ) : (
                  <FaRegEye className="cursor-pointer absolute top-5 right-0 w-12" />
                ))}
            </div>
            <label
              for="confirmPassword"
              className="absolute border-gray-500 text-black font-semibold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Confirm Password
            </label>
          </div>

          {/* Set New Password */}
          {load ? (
            <div className="flex items-center justify-center gap-2 bg-gray-300 w-full p-2 rounded-md shadow-sm">
              <span className="animate-spin">
                <LuLoader2 />
              </span>
              <span>Resetting...</span>
            </div>
          ) : (
            <button
              type="submit"
              className="bg-tertiary w-full p-2 text-white rounded-md hover:shadow-xl"
              onClick={(e) => setNewPassword(e)}
            >
              Register / Reset Password
            </button>
          )}
          {/* Error messages */}
          {error && (
            <p className="mt-2 text-xs text-red-500 absolute">{error}</p>
          )}
        </form>
      )}

      {/* Show password reset success notification */}
      {progress === "Reset Successful" && (
        <div className="text-sm">
          <div className="flex gap-3 text-green-500 py-10">
            <FaCheck className="mt-1" />
            <p className="">Your new password has been created successfully</p>
          </div>
          <p className="">
            Redirecting you to the login page in{" "}
            <span className="text-green-500 font-semibold ml-1">
              {counter?.seconds?.toString()?.padStart(2, "0")}
            </span>
          </p>
        </div>
      )}

      {/* Show instruction notification */}
      {/* {progress === "Verify Code" && (
        <div className="justify-center pt-10 items-center text-xs ">
          In case you did not receive an email with the code, kindly check your{" "}
          <span className="font-semibold">Junk/Spam</span> folder
        </div>
      )} */}
    </div>
  );
};

export default ForgotPassword;
